"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrailingPosition = void 0;
var React = __importStar(require("react"));
var core_1 = require("@material-ui/core");
var TrailingPosition;
(function (TrailingPosition) {
    TrailingPosition[TrailingPosition["start"] = 0] = "start";
    TrailingPosition[TrailingPosition["center"] = 1] = "center";
    TrailingPosition[TrailingPosition["end"] = 2] = "end";
    TrailingPosition[TrailingPosition["none"] = 3] = "none";
})(TrailingPosition = exports.TrailingPosition || (exports.TrailingPosition = {}));
var useStyles = core_1.makeStyles(function (theme) {
    var _a;
    return core_1.createStyles({
        mainContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: theme.spacing(3.5),
            flexWrap: function (props) {
                return props.wrapTrailing ? 'wrap' : 'nowrap';
            },
        },
        leadingContainer: (_a = {
                flex: 1,
                marginBottom: function (props) {
                    return props.trailingPosition === TrailingPosition.none ? 0 : theme.spacing(3);
                },
                flexBasis: '100%',
                order: function (props) { return props.leadingContainerOrder; }
            },
            _a[theme.breakpoints.up('md')] = {
                flexBasis: 0,
                marginBottom: function () { return 0; },
                marginRight: function (props) {
                    return props.trailingPosition === TrailingPosition.none ? 0 : 19;
                },
            },
            _a.position = 'relative',
            _a),
        trailingContainer: {
            marginTop: function (props) {
                return props.trailingPosition === TrailingPosition.end ? 'auto' : 'unset';
            },
            marginBottom: function (props) {
                return props.trailingPosition === TrailingPosition.start ? 'auto' : 'unset';
            },
            order: function (props) { return props.trailingOrder; },
            width: function (props) { return (props.wrapTrailing ? '100%' : 'unset'); },
        },
        regularText: {
            fontWeight: 400,
        },
        boldText: {
            fontWeight: 600,
        },
    });
});
// Represents an edit option on the ControlPanel.
function ConfigOption(_a) {
    var title = _a.title, _b = _a.mobile, mobile = _b === void 0 ? false : _b, subtitle = _a.subtitle, leading = _a.leading, trailing = _a.trailing, trailingPosition = _a.trailingPosition, wrapTrailing = _a.wrapTrailing, _c = _a.leadingContainerOrder, leadingContainerOrder = _c === void 0 ? 1 : _c, _d = _a.trailingOrder, trailingOrder = _d === void 0 ? 2 : _d;
    var classes = useStyles({
        trailingPosition: trailingPosition,
        wrapTrailing: wrapTrailing,
        leadingContainerOrder: leadingContainerOrder,
        trailingOrder: trailingOrder,
    });
    var theme = core_1.useTheme();
    var isMobileView = core_1.useMediaQuery(theme.breakpoints.down('sm'));
    var titleVariant = mobile ? 'h6' : 'body2';
    var titleClass = mobile && !isMobileView ? '' : classes.boldText;
    return (React.createElement("main", { className: classes.mainContainer },
        React.createElement("section", { className: classes.leadingContainer },
            React.createElement(core_1.Typography, { variant: titleVariant, className: titleClass }, title),
            subtitle && (React.createElement(core_1.Typography, { variant: "body1", color: "textSecondary" }, subtitle)),
            leading),
        React.createElement("section", { className: classes.trailingContainer }, trailing)));
}
exports.default = ConfigOption;
