"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@material-ui/core");
var types_1 = require("./types");
exports.default = core_1.makeStyles(function (theme) {
    return core_1.createStyles({
        root: {
            width: '100%',
            position: function (_a) {
                var position = _a.position;
                return position;
            },
            top: '100%',
            left: 0,
        },
        message: {
            width: '100%',
            backgroundColor: function (_a) {
                var type = _a.type;
                return type === types_1.CollapsibleMessageType.Success ? '#eaf9e7' : '#ffeded';
            },
            display: 'flex',
            alignItems: 'center',
            color: function (_a) {
                var type = _a.type;
                return type === types_1.CollapsibleMessageType.Success ? '#6d9067' : '#c85151';
            },
            fontSize: '13px',
            fontWeight: 400,
            padding: theme.spacing(1, 1.5),
        },
    });
});
