"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWindowSize = void 0;
var react_1 = require("react");
var useWindowSize = function () {
    var isClient = typeof window === 'object';
    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
        };
    }
    var _a = __read(react_1.useState(getSize), 2), windowSize = _a[0], setWindowSize = _a[1];
    react_1.useEffect(function () {
        if (!isClient)
            return;
        var handleResize = function () { return setWindowSize(getSize()); };
        window.addEventListener('resize', handleResize);
        // eslint-disable-next-line consistent-return
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    return windowSize;
};
exports.useWindowSize = useWindowSize;
exports.default = exports.useWindowSize;
