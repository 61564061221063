"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_redux_1 = require("react-redux");
function useIsFiltered() {
    var tableConfig = react_redux_1.useSelector(function (state) { return state.user.tableConfig; });
    var searchText = tableConfig.searchText, tags = tableConfig.tags;
    var filtered = Object.values(tableConfig.filter).some(function (value) { return !!value; });
    return !!searchText || !!tags || filtered;
}
exports.default = useIsFiltered;
