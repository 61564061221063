"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.drawerReducer = exports.DrawerActions = exports.initialState = void 0;
exports.initialState = {
    controlPanelIsOpen: false,
    relevantShortLink: null,
    qrCodeModalIsOpen: false,
    uploadFileError: null,
    linkHistoryIsActive: false,
};
exports.DrawerActions = {
    openControlPanel: 'OPEN_CONTROL_PANEL',
    closeControlPanel: 'CLOSE_CONTROL_PANEL',
    openQrCodeModal: 'OPEN_QR_CODE_MODAL',
    closeQrCodeModal: 'CLOSE_QR_CODE_MODAL',
    setUploadFileError: 'SET_UPLOAD_FILE_ERROR',
    activateLinkHistory: 'TOGGLE_LINK_HISTORY',
};
function drawerReducer(state, action) {
    var newState = {};
    switch (action.type) {
        case exports.DrawerActions.openControlPanel:
            newState.controlPanelIsOpen = true;
            newState.relevantShortLink = action.payload;
            break;
        case exports.DrawerActions.closeControlPanel:
            newState.linkHistoryIsActive = false;
            newState.controlPanelIsOpen = false;
            newState.relevantShortLink = null;
            break;
        case exports.DrawerActions.openQrCodeModal:
            newState.qrCodeModalIsOpen = true;
            break;
        case exports.DrawerActions.closeQrCodeModal:
            newState.qrCodeModalIsOpen = false;
            break;
        case exports.DrawerActions.setUploadFileError:
            newState.uploadFileError = action.payload;
            break;
        case exports.DrawerActions.activateLinkHistory:
            newState.linkHistoryIsActive = !state.linkHistoryIsActive;
            break;
        default:
            throw new Error("Undefined modal action: " + action.type);
    }
    return __assign(__assign({}, state), newState);
}
exports.drawerReducer = drawerReducer;
exports.default = exports.DrawerActions;
