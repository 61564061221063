"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function usePrevState(currentState, updateState) {
    if (updateState === void 0) { updateState = true; }
    var prevState = react_1.useRef(currentState);
    react_1.useEffect(function () {
        if (updateState)
            prevState.current = currentState;
    });
    return prevState.current;
}
exports.default = usePrevState;
